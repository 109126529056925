<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>Casino&Slot</sub-title>
                <div class="main_panel">
                    <div class="casino_panel">
                        <div class="casino_re_panel">
                            <div class="p1">
                                <i class="fa fa-won" style="color: #bc955b"></i> Casino Deposit
                            </div>
                            <div class="p2" style="text-align: center">
                                <span style="width: 100%;height: 28px;line-height: 28px">Convert your cash into casino chips</span>
                            </div>
                            <div class="p3">
                                <button class="btn-change cbg1" @click="change2casinocash()">Cash -> Casino chip</button>
                            </div>
                        </div>
                        <div class="casino_re_panel">
                            <div class="p1">
                                <i class="fa fa-database" style="color: #bc955b"></i> Casino Withdraw
                            </div>
                            <div class="p2" style="text-align: center">
                                <span style="width: 100%;height: 28px;line-height: 28px">Switch casino chips</span>
                            </div>
                            <div class="p3">
                                <button class="btn-change cbg2" @click="change2sportsmoney()"> Casino chip -> Cash</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="cs_panel_button">
                    <button class="btn-casino" :class="{'btn-cs-active':type==1}"
                            @click="change(1)">Casino
                    </button>
                    <button class="btn-slot" :class="{'btn-cs-active':type==2}"
                            @click="change(2)">Slot
                    </button>
                </div>

                <div class="casino_panel" v-if="type==1">
                    <div class="casino">
                        <div @click="openGame(HONOR_EVO_GAMEID)">
                            <img src="../../assets/images/casino/casino-evo.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_DREAM_GAMEID)">
                            <img src="../../assets/images/casino/casino-dream.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_ASIAGAME_GAMEID)">
                            <img src="../../assets/images/casino/casino-asia.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_PRAGMATIC_GAMEID)">
                            <img src="../../assets/images/casino/casino-prag.jpg" alt="">
                        </div>
                        <div @click="openGame(HONOR_MICRO_GAMEID)">
                            <img src="../../assets/images/casino/casino-micro.png" alt="">
                        </div>
                    </div>

                </div>
                <div class="casino_panel" v-if="type == 2">
                    <div class="slot-btns">
                        <img src="../../assets/images/casino/slot-pg.png" @click="getGameListByCode('2010')" alt="">

                        <img src="../../assets/images/casino/slot-aristro.png" @click="getGameListByCode('2021')"
                             alt="">

                        <img src="../../assets/images/casino/slot-haba.png" @click="getGameListByCode('2017')"
                             alt="">
                        <img src="../../assets/images/casino/slot-cq9.png" @click="getGameListByCode('2009')" alt="">

                        <img src="../../assets/images/casino/slot-playstar.png" @click="getGameListByCode('2019')"
                             alt="">
                        <img src="../../assets/images/casino/slot-hacksaw.png" @click="getGameListByCode('2034')"
                             alt="">
                        <img src="../../assets/images/casino/slot-relax.png" @click="getGameListByCode('2012')"
                             alt="">
                        <img src="../../assets/images/casino/slot-boong.png" @click="getGameListByCode('2001')"
                             alt="">
                        <img src="../../assets/images/casino/slot-playson.png" @click="getGameListByCode('2002')"
                             alt="">
                        <img src="../../assets/images/casino/slot-nolimit.png" @click="getGameListByCode('2013')"
                             alt="">
                        <img src="../../assets/images/casino/slot-bgaming.png" @click="getGameListByCode('2004')"
                             alt="">
                        <img src="../../assets/images/casino/slot-yggdrasil.png" @click="getGameListByCode('2011')"
                             alt="">
                    </div>
                    <div class="search">
                        <input type="text" v-model="searchVal" @input="search" placeholder="Search for slot 2 or more character">
                    </div>
                    <div class="slot_game">
                        <div class="slot-item" v-for="(item,index) in slotGameList" :key="index"
                             @click="openGame(item.id,item.vendor)" v-if="item.isshow">
                            <img :src="item.img" alt="">
                            <!--                            <span class="name">{{item.langs['ko']}}</span>-->
                            <span class="name">{{item.tEN}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <!--위로가기-->
        <scroll-top-comp></scroll-top-comp>
        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import SubTitle from "../../components/SubTitle";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {
        addMemberCasinoCash,
        getAccountBalance, getCasinoMaintaining,
        getGameList,
        getGameUrl, subjectMemberCasinoCash
    } from "../../network/casinoXimax2Request";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";

    export default {
        name: "CasinoSlotGameHonor",
        mixins: [postionMixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp, TopbarComp, SubTitle, ScrollTopComp, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                currentSlotCode: '2010',
                slotGameList: [],
                sportsConst,
                casinocash: 0,
                position: "Casino",
                searchVal: '',
                type: 1,

                HONOR_EVO_GAMEID : "1901_0001",
                HONOR_DREAM_GAMEID : "1006_0001",
                HONOR_ASIAGAME_GAMEID : "1008_0001",
                HONOR_PRAGMATIC_GAMEID : "1010_0001",
                HONOR_MICRO_GAMEID : "1902_0001",

            }
        },
        methods: {
            change(type) {
                this.type = type
            },
            search() {
                if (this.searchVal === '') {
                    this.slotGameList.forEach(g => {
                        this.$set(g, 'isshow', true)
                    });
                } else {

                    this.slotGameList.forEach(g => {
                        if (g.tEN.indexOf(this.searchVal) !== -1) {
                            this.$set(g, 'isshow', true)
                        } else {
                            this.$set(g, 'isshow', false)
                        }

                    });
                }
            },
            openGame(gameId) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getGameUrl(gameId, 1).then(res => {
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            getGameListByCode(thirdpartycode) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getGameList(thirdpartycode).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.slotGameList = JSON.parse(res.data.data);
                        this.slotGameList.forEach(g => {
                            this.$set(g, 'isshow', true)
                        });
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                });
            },
            change2casinocash() {
                if (this.casinocash > this.$store.state.userInfo.cash) {
                    this.$swal({
                        //title: '보유금액 ' + this.$store.state.userInfo.cash + '원을 초과하였습니다',
                        title: 'The amount held exceeds ' + this.$store.state.userInfo.cash + 'won',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$store.commit(RECEIVE_SHOW_LOADING)
                addMemberCasinoCash(this.casinocash).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.$swal({
                            //title: '전환성공',
                            title: 'Conversion success',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.casinocash = 0;
                        this.$store.dispatch('actionUserInfo')
                        this.getCasinoBalance();

                    } else {
                        this.$swal({
                            //title: '전환실패, ' + res.data.msg,
                            title: 'Conversion failed, ' + res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            change2sportsmoney() {
                this.$swal({
                    //title: '보유머니로 전환 하시겠습니까?',
                    title: 'Would you like to convert it to your own money?',
                    type: 'question',
                    confirmButtonText: ' Yes ',
                    cancelButtonText: 'Not'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.$store.commit(RECEIVE_SHOW_LOADING)
                        subjectMemberCasinoCash().then((res) => {
                            this.$store.commit(RECEIVE_HIDE_LOADING)
                            if (res.data.success) {
                                this.$swal({
                                    // title: '전환성공',
                                    title: 'Conversion success',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.casinocash = 0;
                                this.$store.dispatch('actionUserInfo')
                                this.getCasinoBalance();
                            } else {
                                this.$swal({
                                    // title: '전환실패, ' + res.data.msg,
                                    title: 'Conversion failed, ' + res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }

                        })
                    }
                })

            },
            getCasinoBalance(){
                setTimeout(() => {
                    getAccountBalance().then(res => {
                        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                    })
                }, 1000)
            }

        },
        created() {
            if (this.$store.state.isLogin) {
                if(this.$route.query.type){
                    this.type = this.$route.query.type
                    this.change(this.type)
                }
                getCasinoMaintaining().then(res => {
                    if (res.data.success) {
                        if (!res.data.data) {
                            this.$swal({
                                //title: '카지노 점검중입니다',
                                title: 'The casino is being inspected.',
                                type: 'error',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        } else {
                            this.getGameListByCode(this.currentSlotCode);
                        }
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                })
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .casino_panel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 16px;
    }

    .casino_panel .casino_re_panel {
        width: 49.6%;
        height: 160px;
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--noticeBg);
    }

    .casino_panel .casino_re_panel .p1 {
        width: 100%;
        text-align: center;
        padding: 10px 0;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
    }

    .casino_panel .casino_re_panel .p2 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        text-align: center;
        padding: 10px 0;
        color: #fff9ff;
        font-size: 14px;
        font-weight: 600;
    }

    .casino_panel .casino_re_panel .p2 .t1 {
        text-align: center;
        width: 30%;
    }

    .casino_panel .casino_re_panel .p2 .t2 {
        text-align: center;
        width: 40%;
    }

    .t2 input {
        width: 100%;
        text-align: center;
        background-color: #ffffff;
        height: 28px;
        color: #0b1423;
    }

    .casino_panel .casino_re_panel .p2 .t3 {
        text-align: center;
        width: 30%;
    }

    .casino_panel .casino_re_panel .p2 .t3 button {
        height: 28px;
        padding: 0 3px;
        border-radius: 1px;
    }

    .casino_panel .casino_re_panel .p2 .btn-all {
        border: 2px solid var(--btn01Bg);
    }

    .casino_panel .casino_re_panel .p2 .btn-reset {
        background-color: var(--btn01Bg);
        color: #ffffff;
        margin-left: 5px;
    }

    .casino_panel .casino_re_panel .p3 {
        text-align: center;
    }

    .casino_panel .casino_re_panel .btn-change {
        height: 36px;
        padding: 0 16px;
        border-radius: 1px;
        color: #353535;
        font-weight: 600;
    }
    .casino_panel .casino_re_panel .cbg1{
        background: linear-gradient(180deg, #fffd4f, #fffd70) !important;
    }
    .casino_panel .casino_re_panel .cbg2{
        background: linear-gradient(180deg, #ac7eff, #c09eff) !important;
    }

    .casino_panel .slot-btns {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .casino_panel .slot-btns img {
        cursor: pointer;
        width: 16.4%;
        --max-width: 180px;
    }
    .casino_panel .slot-btns img:hover {
        transition: .5s;
        opacity: .5;
    }

    .casino_panel .btn-slot-comp {
        background-color: var(--btn01Bg);
        margin-bottom: 2px;
        height: 32px;
        line-height: 32px;
        border-radius: 1px;
        color: #fff9ff;
        font-weight: normal;

    }


    .casino_panel .search {
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .casino_panel .search input {
        color: #0c0e0e;
        background-color: #cfcfd1;
        width: 300px;
        height: 32px;
        text-align: center;
    }

    .cs_panel_button {
        margin: 20px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btn-casino {
        border-radius: 1px;
        padding: 10px 0;
        width: 49.5%;
        background-color: var(--sportsKindButtonBg1);
        color: #fff9ff;
    }

    .btn-slot {
        --border: 1px solid var(--loginBtnBg);
        border-radius: 1px;
        padding: 10px 0;
        width: 49.5%;
        background-color: var(--sportsKindButtonBg1);
    }

    .btn-cs-active {
        background: linear-gradient(180deg, var(--sportsKindButtonBgActive1), var(--sportsKindButtonBgActive2)) !important;
        color: #fff9ff;
        font-weight: 600;
    }

    @media screen and (max-width: 1024px) {
        .casino_panel .casino_re_panel {
            width: 100% !important;
            margin-top: 5px!important;
        }

    }

    .button_group a {
        margin-top: 3px;
    }

    .casino_panel .casino {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    .casino_panel .casino div {
        width: 19.5%;
        box-sizing: border-box;
        padding: 10px 10px;
        text-align: center;
    }
    .casino_panel .casino div:hover {
        transition: 0.5s;
        opacity: .5;
    }

    .casino_panel .casino {
        max-width: 100%;
        cursor: pointer;
    }

    .casino img {
        width: 100%;
        border: 1px solid var(--casinoimgbordercolor);
        border-radius: 1px;
    }

    .casino_panel .slot_company {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
        margin: 10px auto;
    }


    .casino_panel .slot_game {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 10px;
    }

    .casino_panel .slot_game .slot-item {
        min-width: 150px;
        max-width: 200px;
        border-bottom: 1px solid #4a4a4a;
        cursor: pointer;
        margin: 5px 5px;
    }

    .casino_panel .slot_game .name {
        display: block;
        line-height: 50px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #9a9a9a;
    }

    .casino_panel .slot_game img {
        width: 100%;
        border: 1px solid #494949;
        border-radius: 5px;
    }

    @media screen and (max-width: 800px) {
        .casino div {
            width: 49.5% !important;
            box-sizing: border-box;
            padding: 10px 10px;
        }
        .casino_panel .slot_game div {
            width: 45.5%;
        }

        .casino_panel .slot_company a {
            width: 48% !important;
        }

        .casino_panel .board_box {
            width: 100% !important;
        }
        .casino_panel .slot-btns img{
            width: 40% !important;
        }

    }
</style>