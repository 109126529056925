import http from "./axios/http";

export function getLobbyUrl(thirdPartyCode) {
  return http.get('/casino/ximax2/get_lobby_url?t=' + new Date().getTime(), {'thirdPartyCode':thirdPartyCode})
}
export function getGameUrl(gameId,isweb) {
  return http.get('/casino/ximax2/get_game_url?t=' + new Date().getTime(), {'gameId':gameId,'isweb':isweb})
}
export function getGameList(thidpartycode) {
  return http.get('/casino/ximax2/get_game_list?t=' + new Date().getTime(), {'thidpartycode':thidpartycode})
}
export function getAccountBalance() {
  return http.get('/casino/ximax2/get_account_balance?t=' + new Date().getTime())
}

export function addMemberCasinoCash(amount) {
  return http.get('/casino/ximax2/add_member_casino_cash?t=' + new Date().getTime(), {'amount':amount})
}
export function subjectMemberCasinoCash() {
  return http.get('/casino/ximax2/subject_member_casino_cash?t=' + new Date().getTime())
}
export function getCasinoMaintaining() {
  return http.get('/casino/ximax2/get_casino_maintaining?t=' + new Date().getTime())
}
export function getXimax2BetList(pageNum, pageSize) {
  return http.get('/casino/ximax2/get_ximax2_bet_list?t=' + new Date().getTime() + '&pageNum=' + '&pageSize=' + pageSize)
}

export function deleteAllHonorBet() {
  return http.get('/casino/ximax2/delete_all?t=' + new Date().getTime())
}

